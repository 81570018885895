import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../validator/must-match.validator';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  regSubmitted: boolean;
  returnMessage: boolean=false;
  returnError: boolean=false;
  constructor(private formBuilder: FormBuilder, private appService: ServicesService) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  get u() { return this.contactForm.controls; }

  FadeOutSuccessMsg() {
    setTimeout( () => {
           this.returnMessage = false;
        }, 4000);
   }

  FadeOutErrorMsg() {
    setTimeout( () => {
           this.returnError = false;
        }, 4000);
   } 

  sendMail(){
    this.regSubmitted = true;
    if (this.contactForm.invalid) {
      console.log('invalid value/ blank value');
      return;
    }
    this.appService.sendMail(this.contactForm.value.name, this.contactForm.value.email,this.contactForm.value.phone,this.contactForm.value.subject,this.contactForm.value.message)
    .pipe()
    .subscribe(
      data => {
        console.log('sendMail Completed :' + data);
        if(data){
          this.regSubmitted = false;
          this.returnMessage = true;
          this.contactForm.reset();
          this.FadeOutSuccessMsg();
        }
        else{
          this.returnError = true;
         this.FadeOutErrorMsg();
        }
      },
      error => {
        console.log('sendMail Error');
        //  this.alertService.error(error);
      });
  }
}
