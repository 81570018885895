import { Component, ViewChild, OnInit } from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';
import { ServicesService } from '../services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-photodetails',
  templateUrl: './photodetails.component.html',
  styleUrls: ['./photodetails.component.css']
})
export class PhotodetailsComponent implements OnInit {
  @ViewChild('owlElement',{static: false}) owlElement: OwlCarousel;
  galleryId: number;
  categoryId: number;
  gellaryDtls: any;
  images: any;
  imgPath: any;
  relImages: any;
  relGellaryDtls: any;
  currLocation: any = window.location.href;
  constructor(private service: ServicesService,private router : Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.galleryId = params['id'];
      this.categoryId = params['catid'];
      console.log('Image Id: '+this.galleryId);
      console.log('category Id: '+this.categoryId);
  });
  this.initCall();
  }

  goTostorylink(categoryid, galleryId) {
    this.galleryId = galleryId;
    this.categoryId = categoryid;
    this.router.navigate(['photodetails'],{ queryParams: {catid: categoryid, id : galleryId}});
    this.initCall();
  }
  initCall() {
    this.service.getphotoDetails(this.galleryId).subscribe((data) => {
      console.log(data);
      this.imgPath = environment.appPath
      this.images = data['galleryItem'];
      this.gellaryDtls=data;
  });

  this.service.getRelatedPhoto(this.categoryId).subscribe((data: any) => {
   console.log(this.galleryId);
   this.relGellaryDtls = data;
   this.relGellaryDtls=this.relGellaryDtls.filter(item => item.galleryId != this.galleryId);
    console.log(this.relGellaryDtls);
  });
  }


  carouselOptions = {
    margin: 25,
    nav: true,
    dots: false,
    navText: ["<div class='nav-btn prev-slide fa fa-angle-left'> </div>", "<div class='nav-btn next-slide fa fa-angle-right'> </div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 1,
        nav: true,
        loop: false
      },
      1500: {
        items: 1,
        nav: true,
        loop: false
      }
    }
  }

  thumbOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide fa fa-angle-left'> </div>", "<div class='nav-btn next-slide fa fa-angle-right'> </div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 3,
        nav: true
      },
      600: {
        items: 4,
        nav: true
      },
      1000: {
        items: 5,
        nav: true,
        loop: false
      },
      1500: {
        items: 5,
        nav: true,
        loop: false
      }
    }
  }

  relatedPhoto = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide fa fa-angle-left'> </div>", "<div class='nav-btn next-slide fa fa-angle-right'> </div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 3,
        nav: true
      },
      1000: {
        items: 3,
        nav: true,
        loop: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  }

  goToSlide(index:any){
    this.owlElement.to(index);
  }
  

}
