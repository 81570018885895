import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termscond',
  templateUrl: './termscond.component.html',
  styleUrls: ['./termscond.component.css']
})
export class TermscondComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
