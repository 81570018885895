import { Component, OnInit } from '@angular/core';

import { ServicesService } from '../services.service';
import { Router,ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  secId:any;
  link1:any='';
  linkdesc1:any='';
  linkdesc2:any='';
  linkdesc3:any='';
  strDetails:any=[];
  secimage1:any;
  secstr1:any={};
  baseImgPath:any;
  cateName:any={}
  homeSlide:any={}
  homeBlock:any={}
  homesectionBlock1img: any;
  homesectionBlock2img: any;
  homesectionBlock3img: any;
  homesectionBlock5img: any;
  homesectionBlock6img: any;
  blockDetails1:any;
  blockDetails2:any;
  blockDetails3:any;
  blockDetails4:any;
  blockDetails5:any;
  blockDetails6:any;
  desc1:any;
  desc2:any;
  desc3:any;
  desc5:any;
  desc6:any;
  homesectionMassonary:any;
  cstart: any = 0;
  cend: any = 9;
  moredata:any=[];
  loadmoretxt:any="LOAD MORE";
  categoryName6: any;
  categoryName5: any;
  categoryName3: any;
  categoryName2: any;
  categoryName1: any;
  latestedi:any={};
  imgPath ='https://api.sananda.in';
  constructor(private router : Router, public activeRoute: ActivatedRoute, private services: ServicesService) { 
    this.baseImgPath =  environment.baseImgPath;
  }

  ngOnInit() { 

    this.activeRoute.queryParams.subscribe(params => {
      this.secId = params['categoryId'];
      this.cateName = params['categoryName'];
      this.link1 = params['link1'];

      this.initialiseState(this.secId); // reset and set based on new parameter this time
  });


  }

  initialiseState(secId) {

  this.services.homeBanner().subscribe ( 
    data => {
     // console.log(this.link1);
      this.strDetails=data;
    },
    error=> {
      console.log(error);   
    });

    this.services.latestEdition().subscribe(
      data => {
        console.log(data);
        this.latestedi=data;
         
      },
      error=>{
        console.log(error);   
      });

  const url = environment.baseURL+'Home-other-stories/start/'+this.cstart+'/end/'+this.cend;
  this.services.moreRecords(url, 0, this.cstart, this.cend).subscribe( 
      data => {
        //console.log(data);
        this.homesectionMassonary=data;
      },
      error =>{
        console.log(error);   
    });

  this.services.homeBlocksection(1).subscribe (
    data => {
     // console.log(this.link1);
      this.blockDetails1=data;
      this.desc1=this.blockDetails1.title;
      this.categoryName1=this.blockDetails1.categoryName;
      this.homesectionBlock1img = this.blockDetails1.imageId;
    },
    error => {
      console.log(error);   
  });

  this.services.homeBlocksection(2).subscribe (
    data => {
     // console.log(this.link1);
     this.blockDetails2=data;
     this.desc2=this.blockDetails2.title;
     this.categoryName2=this.blockDetails2.categoryName;
     this.homesectionBlock2img = this.blockDetails2.imageId;
    },
    error => {
      console.log(error);   
  });

  this.services.homeBlocksection(3).subscribe (
    data => {
     // console.log(this.link1);
     this.blockDetails3=data;
     this.desc3=this.blockDetails3.title;
     this.categoryName3=this.blockDetails3.categoryName;
     this.homesectionBlock3img = this.blockDetails3.imageId;
    },
    error => {
      console.log(error);   
  });

  this.services.homeBlocksection(5).subscribe (
    data => {
     // console.log(this.link1);
     this.blockDetails5=data;
     this.desc5=this.blockDetails5.description;
     this.categoryName5=this.blockDetails5.categoryName;
     this.homesectionBlock5img = this.blockDetails5.imageId;
    },
    error => {
      console.log(error);   
  });

  this.services.homeBlocksection(6).subscribe (
    data => {
     // console.log(this.link1);
     this.blockDetails6=data;
      this.desc6=this.blockDetails6.description;
      this.categoryName6=this.blockDetails6.categoryName;
      this.homesectionBlock6img = this.blockDetails6.imageId;
    },
    error => {
      console.log(error);   
  });
}


goTostorylink(catid, link) {
  this.router.navigate(['storydetails'],{ queryParams: {catid: catid, link : link}});
  let scrollToTop = window.setInterval(() => {
    let pos = window.pageYOffset;
    if (pos > 0) {
        window.scrollTo(0, 0); // how far to scroll on each step
    } else {
        window.clearInterval(scrollToTop);
    }
}, 16);
}

secLoadMore(cend){
  //console.log(this.counter + 'dat size'+this.msonry.length); 
  this.cstart=cend + 1;
  this.cend=this.cstart + 8;
  const url = environment.baseURL+'Home-other-stories/start/'+this.cstart+'/end/'+this.cend;
  this.services.moreRecords(url, 0, this.cstart, this.cend).subscribe(
    data => {
      console.log(data);
      this.moredata=data;      
      this.homesectionMassonary = this.homesectionMassonary.concat(this.moredata);

      if(this.moredata.length==0){
        this.loadmoretxt="NO MORE STORIES";
       }
    },
    error=>{
      console.log(error);   
    });
}

gotoDesc(categoryId, linkdesc){
  console.log(categoryId);
  console.log(linkdesc);
  this.router.navigate(['storydetails'],{ queryParams: {catid: categoryId, link: linkdesc}});
  let scrollToTop = window.setInterval(() => {
    let pos = window.pageYOffset;
    if (pos > 0) {
        window.scrollTo(0, 0); // how far to scroll on each step
    } else {
        window.clearInterval(scrollToTop);
    }
}, 16);
}
printPage() {
  window.print();
}



closePop(){
  var x = document.getElementById("myModal");
  x.style.display = "none";
    document.getElementById('myModal').className += ' popNone';
    document.getElementById('myModal').classList.remove("show");

}

}