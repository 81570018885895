import { Component, OnInit } from '@angular/core';

import { ServicesService } from '../services.service';
import { Router,ActivatedRoute } from "@angular/router";
import { DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../environments/environment';


@Component({
  selector: 'app-anandalokevent',
  templateUrl: './anandalokevent.component.html',
  styleUrls: ['./anandalokevent.component.css']
})
export class AnandalokeventComponent implements OnInit {

  secId:any;
  title:any={};
  video:any={};
  categoryVideoList:any=[];
  secstrvid:any={};
  themekey:any;
  friendlyURL:any;
  videoscenes:any=[];
  nameCurrentValue:any;
  secimage1:any;
  id:any=[];
  aPuroskar:any=[];
  aShortcut:any=[];
  chalachitraUtsav:any=[];
  nId:any=[];
  videoId:any=[];
  vid:any;
  categoryId:any;
  videoStream:any;
  desc:any;
  youvid:any;

  constructor(private router : Router, public activeRoute: ActivatedRoute, private services: ServicesService,  private _sanitizer: DomSanitizer, ) { 

    
  }
 
  ngOnInit() {

   this.activeRoute.queryParams.subscribe(params => {
      this.categoryId = params['categoryId'];
      this.nameCurrentValue = params['nameCurrentValue'];
      this.themekey = params['themekey'];
      this.friendlyURL = params['friendlyURL'];
  });

  this.services.anandaEventMainvideo(33760,33742).subscribe(
    data => {
      this.videoscenes=data;
      console.log(data);
      if(this.videoscenes!=null){
        this.title=this.videoscenes[0].title;
        //this.desc=this.videoscenes[0].desc;
        this.youvid=this._sanitizer.bypassSecurityTrustResourceUrl(environment.youtubeURL+this.videoscenes[0].videoId+'?rel=0&autoplay=1');
      }
    },
    error =>{
      console.log(error);   
    });

  



  this.aPuroskar = this.getAPuroskar(33742); // 
  this.aShortcut = this.getAShortcut(33743); //
  this.chalachitraUtsav = this.getChalachitraUtsav(33744); //

  }

  getAPuroskar(secId){
    
    this.services.videoEventCategoryStrip(secId).subscribe(
      data => {
        this.aPuroskar=data;
      console.log(data);
      },
      error =>{
        console.log(error);   
      });

      return this.categoryVideoList;

    }

    getAShortcut(secId){
    
      this.services.videoEventCategoryStrip(secId).subscribe(
        data => {
          this.aShortcut=data;
        console.log(data);
        },
        error =>{
          console.log(error);   
        });
  
        return this.categoryVideoList;
  
      }

      getChalachitraUtsav(secId){
    
        this.services.videoEventCategoryStrip(secId).subscribe(
          data => {
            this.chalachitraUtsav=data;
          console.log(data);
          },
          error =>{
            console.log(error);   
          });
    
          return this.categoryVideoList;
    
        }

      goApuroskarDetails(catId, id){
        console.log('section Id :'+id);
        this.router.navigate(['videodetails'],{ queryParams: {id : id, catId: catId}});
        let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, 0); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);
      }
      goAshortcutDetails(catId, id){
        console.log('section Id :'+id);
        this.router.navigate(['videodetails'],{ queryParams: {id : id, catId: catId}});
        let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, 0); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);
      }
      goAChalachitraDetails(catId, id){
        console.log('section Id :'+id);
        this.router.navigate(['videodetails'],{ queryParams: {id : id, catId: catId}});
        let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
              window.scrollTo(0, 0); // how far to scroll on each step
          } else {
              window.clearInterval(scrollToTop);
          }
      }, 16);
      }
        


  carouselOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'> < </div>", "<div class='nav-btn next-slide'> > </div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: true
      },
      1000: {
        items: 3,
        nav: true,
        loop: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  }


}
