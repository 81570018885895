import { Component, ViewChild, OnInit } from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';
import { ServicesService } from '../services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-videodetails',
  templateUrl: './videodetails.component.html',
  styleUrls: ['./videodetails.component.css']
})
export class VideodetailsComponent implements OnInit {
vid:any;
catId:any;
title:any;
videoStream:any;
desc:any;
videoscenes:any=[];
videoId:any=[];
relatedTitle:any;
relatedvideoStream:any;
selectedItem:any;
relatedVideoList: any={};
  constructor(private service: ServicesService, private router : Router, private activeRoute: ActivatedRoute,  private _sanitizer: DomSanitizer ) { }

  ngOnInit() {

    this.activeRoute.queryParams.subscribe(params => {
      this.vid = params['id'];
      this.catId = params['catId'];
      console.log(' Id: '+this.vid);
      console.log('category Id: '+this.catId);

      this.videoId = params['videoId'];
  });

  this.service.getvideoDetails(this.vid).subscribe((data) => {
    console.log(data);
    this.title = data['title'];
    this.videoStream=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+data['videoId']+'?rel=0');
    this.desc = data['description'];

  });


  this.service.relatedVideo(this.vid, this.catId).subscribe((data) => {
    this.relatedVideoList=data;
    console.log(data);
    },
    error =>{
      console.log(error);   
    });
  }

  openBig(cnt){
    console.log(cnt);
    this.selectedItem = cnt.videoId;
    this.relatedTitle=cnt.title;
    this.relatedvideoStream=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.selectedItem+'?rel=0');
  }

}
