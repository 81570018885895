import { Component, OnInit } from '@angular/core';

import { ServicesService } from '../services.service';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-storydetails',
  templateUrl: './storydetails.component.html',
  styleUrls: ['./storydetails.component.css']
})
export class StorydetailsComponent implements OnInit {

  secId: any;
  artId: any = '';
  link: any = '';
  auth: any = '';
  tags: any = '';
  title: any = {};
  secstr1: any = {};
  strDetails: any = [];
  secimage1: any;
  baseImgPath: any;
  catid: any;
  relDetails: any;
  topNews: any;
  cstart: any = 0;
  cend: any = 9;
  loadmoretxt: any = "LOAD MORE";
  moredata: any = [];
  storyBody: any;
  categoryName: any;
  currLocation: any;
  constructor(private router: Router, public activeRoute: ActivatedRoute, private services: ServicesService, private sanitized: DomSanitizer) { }

  ngOnInit() {
    this.baseImgPath = environment.baseImgPath;
    this.activeRoute.queryParams.subscribe(params => {
      this.link = params['link'];
      this.catid = params['catid'];
      console.log(this.catid);
      this.currLocation = 'https://www.anandalok.in/api/web/guest/share/-/sharepage-' +this.catid + "-" + this.link;
    });
    this.initCall();
  }

  goTostorylink(categoryid, articleId) {
    this.link = articleId;
    this.catid = categoryid;
    this.router.navigate(['storydetails'], { queryParams: { catid: categoryid, link: articleId } });
    this.initCall();

    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, 0); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  transform(value) {
    console.log(this.sanitized.bypassSecurityTrustHtml(value))
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  initCall() {
    this.services.storyDetails(this.link, this.categoryName).subscribe(
      data => {
        console.log(this.link);
        this.strDetails = data;
        this.storyBody = this.transform(this.strDetails.body);

        this.secimage1 = environment.baseImgPath + this.strDetails.imageId;
      },
      error => {
        console.log(error);
      });

    //
    this.services.relCaro(this.catid, this.link).subscribe(
      data => {
        console.log(data);
        this.relDetails = data;
      },
      error => {
        console.log(error);
      });
    const url = environment.baseURL + 'Home-other-stories/category-id/' + this.catid + '/start/' + this.cstart + '/end/' + this.cend;
    this.services.moreRecords(url, this.catid, this.cstart, this.cend).subscribe(
      data => {
        //console.log(data);
        this.topNews = data;
      },
      error => {
        console.log(error);
      });
  }

  carouselOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'> < </div>", "<div class='nav-btn next-slide'> > </div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: true
      },
      1000: {
        items: 3,
        nav: true,
        loop: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  }

  secLoadMore(secId, cend) {
    //console.log(this.counter + 'dat size'+this.msonry.length);
    this.cstart = cend + 1;
    this.cend = this.cstart + 8;
    const url = environment.baseURL + 'Home-other-stories/category-id/' + this.catid + '/start/' + this.cstart + '/end/' + this.cend;
    this.services.moreRecords(url, secId, this.cstart, this.cend).subscribe(
      data => {
        console.log(data);
        this.moredata = data;
        this.topNews = this.topNews.concat(this.moredata);

        if (this.moredata.length == 0) {
          this.loadmoretxt = "NO MORE STORIES";
        }
      },
      error => {
        console.log(error);
      });
  }

  goToSearch(tag) {
    this.router.navigate(['searchresult'], { queryParams: { key: encodeURIComponent(tag) } });
  }

}
