import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';


import { Router } from '@angular/router';
import { EventsService } from 'angular4-events';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  footerMenu: any;

  constructor(private service:ServicesService, private router : Router, private events: EventsService) { }

  ngOnInit() {
    this.service.getFooterMenu().subscribe((res)=>{
      this.footerMenu=res;
      console.log( this.footerMenu);
  }); 
  }

  goToSelected(friendlyURL, categoryId, nameCurrentValue, themeKey) {
    if(themeKey=='video'){
      this.router.navigate(['behindthesceen'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue, themeKey: themeKey}});
    }else{
      this.router.navigate(['section'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue}});
    }
}

}
