import { Component, OnInit, Directive, EventEmitter, ElementRef, HostListener, Output} from '@angular/core';
import { ServicesService } from '../services.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Token } from '../Token';


// import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { EventsService } from 'angular4-events';
import {Renderer2} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-navpanel',
  templateUrl: './navpanel.component.html',
  styleUrls: ['./navpanel.component.css'],
  animations: [
    trigger('childAnimation', [
      // ...
      state('open', style({
        width: '250px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      state('closed', style({
        width: '100px',
        opacity: 0.5,
        backgroundColor: 'green'
      })),
      transition('* => *', [
        animate('1s')
      ]),
    ]),
  ],
})

@Directive({ selector: '[clickElsewhere]' })
export class NavpanelComponent implements OnInit {
  panelOpenState = false;
  @Output() clickElsewhere = new EventEmitter<MouseEvent>(); 
  token:Token;
  friendlyURL: any;
  categoryId: any;
  top: any;
  posts: any;
  nameCurrentValue: any;
  themeKey: any;
  isOpen = true;
  animationState = 'out';
 
  shouldShow = true;
  
  constructor ( private elementRef: ElementRef, private service:ServicesService, private router : Router, private events: EventsService, private renderer: Renderer2) {}
  
  
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
   const targetElement = event.target as HTMLElement;
   const el: HTMLElement = document.getElementById('myNavbar');
   if(!targetElement.classList.contains("search-box-elem"))
    {
        el.classList.remove("in");
    }

      if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
       if(!targetElement.classList.contains("openbtn"))  
       {
         this.closeNav();
        }
         this.clickElsewhere.emit(event);
      }
  }



  ngOnInit() {
    this.events.subscribe('token').subscribe((token)=>{
      this.token = this.service.getToken;
    });
    if(this.service.getToken != null){
      this.token = this.service.getToken;
    }
    this.service.getPosts().subscribe((res)=>{
          this.posts=res;
          console.log( this.posts);
      }); 
     
  }


  closeNav() {
    console.log("hello world");
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    if(document.getElementById("screener")!=null){
      document.getElementById("screener").style.marginLeft = "0";
    }
  }

  
  openNav() {
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
    document.getElementById("screener").style.marginLeft = "250";
  }



  openLogin(){
    this.events.publish('login', true);
  }

	openRegi(){
    this.events.publish('login', false);
  }
  
  openLogout() {
    this.service.clearToken();
    this.events.publish('token', this.service.getToken);
    this.openLogin();
  }

    
  goToSelected(friendlyURL, categoryId, nameCurrentValue, themeKey) {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
      if(themeKey=='video'){
        this.router.navigate(['behindthesceen'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue, themeKey: themeKey}});
      }else if(themeKey=='anandalokevent'){
        this.router.navigate(['anandalokevent'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue, themeKey: themeKey}});
      }else if(themeKey=='photogallery'){
        this.router.navigate(['photogallery'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue, themeKey: themeKey}});
      }else{
        this.router.navigate(['section'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue}});
      }
  }
}
