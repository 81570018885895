import { Component, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'angular4-events';
import { ServicesService } from '../services.service';
import { Token } from '../Token';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';





declare var $: any;
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html', 
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
  @ViewChild(MatMenuTrigger,{static:false}) trigger: MatMenuTrigger;
 
  token:Token;
  topMenu: any;
  themeKey: any;
  top: any;
  keyword:any="";
  subMenu: boolean;
  currentMenuIndex=-1;
  maxMenuIndex=0;
  menuObjects={};
  constructor(private service:ServicesService, private router : Router, private events: EventsService) { }

  ngOnInit() {
    this.events.subscribe('token').subscribe((token)=>{
      this.token = this.service.getToken;
    });
    if(this.service.getToken != null){
      this.token = this.service.getToken;
    }
    this.service.getTopMenu().subscribe((res)=>{
        this.topMenu=res;
        console.log( this.topMenu);
  });
  }

  
  openNav() {
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "0";
    // document.getElementById("screener").style.marginLeft = "250px";
  }

  openNavMenu(e,tgr,index){
    e.stopPropagation();
    e.preventDefault();
    if(this.maxMenuIndex<index){
      this.maxMenuIndex=index;
    }
    if(!this.menuObjects[index]){
      this.menuObjects[index]=tgr;
    }
    //console.log( e.target);
    this.currentMenuIndex=index;
    console.log(this.currentMenuIndex);

    for(var i=0;i<=this.maxMenuIndex;i++){
      if(this.menuObjects[i]){
        this.menuObjects[i].closeMenu();
      }
    }
    //e.target.classList.contains("active");
    tgr.openMenu();
  }

  closeNavMenu(e,tgr,index){
    e.stopPropagation();
    e.preventDefault();
    //console.log("bbbbbbbbbbbbbbbbbbbbbb");
    if(this.currentMenuIndex!=index){
      //tgr.closeMenu();
    }
  }

  closeNav() {
    console.log("hello world");
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    if(document.getElementById("screener")!=null){
      document.getElementById("screener").style.marginLeft = "0";
    }
  }

  openLogin(){
    this.events.publish('login', true);
  }

	openRegi(){
    this.events.publish('login', false);
  }
  
  openLogout() {
    this.service.clearToken();
    this.events.publish('token', this.service.getToken);
    this.openLogin();
  }

  goToSelected(friendlyURL, categoryId, nameCurrentValue, themeKey) {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
    if(themeKey=='video'){
      this.router.navigate(['behindthesceen'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue, themeKey: themeKey}});
    }else if(themeKey=='anandalokevent'){
      this.router.navigate(['anandalokevent'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue, themeKey: themeKey}});
    }else if(themeKey=='photogallery'){
      this.router.navigate(['photogallery'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue, themeKey: themeKey}});
    }else{
      this.router.navigate(['section'],{ queryParams: {friendlyURL : friendlyURL, categoryId: categoryId, nameCurrentValue: nameCurrentValue}});
    }
}

  goToSearch(){
    console.log(encodeURI(this.keyword));
    const key = encodeURIComponent(this.keyword);
    this.keyword = '';
    this.router.navigate(['searchresult'],{ queryParams: {key: key}});
  }


}
