import { Component, OnInit } from '@angular/core';

import { ServicesService } from '../services.service';
import { Router,ActivatedRoute } from "@angular/router";
import {environment} from '../../environments/environment';
@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})
export class SectionComponent implements OnInit {
  secId:any;
  sName:any='';
  themekey:any='';
  parent:any='';
  secstr1:any={};
  sectionMasionary:any=[];
  secimage1:any;
  baseImgPath:any;
  sectionName:any;
  loadmoretxt:any="LOAD MORE";
  link:any;
  cstart: any = 0;
  cend: any = 9;
  moredata:any=[];
  constructor(private router : Router, public activeRoute: ActivatedRoute, private services: ServicesService) {
   this.baseImgPath =  environment.baseImgPath;
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.secId = params['categoryId'];
      // this.sName = params['secName'];
      this.themekey = params['themekey'];
      // this.parent = params['parent'];
      this.sectionName = params['nameCurrentValue'];
     
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

      this.initialiseState(this.secId); // reset and set based on new parameter this time

  });
  }

  initialiseState(secId){
    this.services.secTopStory('1', secId).subscribe(
      data => {
        //console.log(data);
        this.secstr1=data;
        this.secimage1=environment.baseImgPath+this.secstr1.imageId;
      },
      error =>{
        console.log(error);   
      });
    const url = environment.baseURL+'Section-other-stories/category-id/'+secId+'/start/'+this.cstart+'/end/'+this.cend
    // this.services.secMasonary(secId,this.startNo,this.endNo).subscribe( 
    this.services.moreRecords(url, secId, this.cstart, this.cend).subscribe(
      data => {
        //console.log(data);
        this.sectionMasionary=data;
      },
      error =>{
        console.log(error);   
      });

    }

   
    goToDetails(categoryId, linkdesc){
      this.router.navigate(['storydetails'],{ queryParams: {catid: categoryId, link: linkdesc}});
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, 0); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
    }



    secLoadMore(secId,cend){
      //console.log(this.counter + 'dat size'+this.msonry.length); 
      this.cstart=cend + 1;
      this.cend=this.cstart + 9;
      //const url = environment.baseURL+'Home-other-stories/start/'+this.cstart+'/end/'+this.cend;
      const url = environment.baseURL+'Section-other-stories/category-id/'+secId+'/start/'+this.cstart+'/end/'+this.cend
      this.services.moreRecords(url, secId, this.cstart, this.cend).subscribe(
        data => {
          console.log(data);
          this.moredata=data;      
          this.sectionMasionary = this.sectionMasionary.concat(this.moredata);

          if(this.moredata.length==0){
            this.loadmoretxt="NO MORE STORIES";
           }
        },
        error=>{
          console.log(error);   
        });
    }


    






  goTomaslink(catId, link) {
      console.log(link);
      this.router.navigate(['storydetails'],{ queryParams: {catid: catId, link : link}});
  }
}
