import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css']
})
export class SearchresultComponent implements OnInit {
  keyword: any;
  srchesult: any;
  baseImgPath: any;
  endLmt: any = 9;
  startLmt: any = 0;
  moredata: Object;

  constructor(private service: ServicesService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.baseImgPath=environment.baseImgPath;
    this.activeRoute.queryParams.subscribe(params => {
    this.keyword = decodeURIComponent(params['key']);
    this.searchResult();
    }); 
  }
  secLoadMore(secid, cend){
    console.log(secid + 'secid <=> cend'+cend);
    this.startLmt =cend + 1;
    this.endLmt=this.startLmt + 9;
   // this.searchResult();
    this.service.searchResult(this.keyword, this.startLmt, this.endLmt).subscribe((data) => {
      console.log('Keyword=> '+this.keyword);
      console.log('data from secLoadMore => '+data);
      this.moredata=data;      
      this.srchesult = this.srchesult.concat(this.moredata); 
   },
   error => {
    console.log(error);
   },
   () => {
     // console.log('onCompleted called :' +this.srchesult? this.srchesult.length : '0');
     // 'onCompleted' callback.
     // No errors, route to new page here
   });
  }
  searchResult() {    
    this.service.searchResult(this.keyword, this.startLmt, this.endLmt).subscribe((data) => {
      console.log('Keyword=> '+this.keyword);
      console.log('data from searchResult => '+data);      
      this.srchesult = data; 
   },
   error => {
    console.log(error);
   },
   () => {
     // console.log('onCompleted called :' +this.srchesult? this.srchesult.length : '0');
     // 'onCompleted' callback.
     // No errors, route to new page here
   });
}

goTostorylink(catid, link) {
  this.router.navigate(['storydetails'],{ queryParams: {catid: catid, link : link}});
  let scrollToTop = window.setInterval(() => {
    let pos = window.pageYOffset;
    if (pos > 0) {
        window.scrollTo(0, 0); // how far to scroll on each step
    } else {
        window.clearInterval(scrollToTop);
    }
}, 16);
}

}
