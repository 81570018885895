import { Component} from '@angular/core';
import { Router,NavigationEnd } from "@angular/router";
declare let gtag:any;

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  openlogin:boolean=true;
  openregist:boolean=false;
  title = 'Anandalok';
constructor(public router: Router) {
this.router.events.subscribe(event => {
if (event instanceof NavigationEnd) {
console.log(event.urlAfterRedirects);
gtag('config','UA-42402175-1',{'page_path':event.urlAfterRedirects});
}
});
}


}
