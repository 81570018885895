// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://anandalokcms.abp.in/',
  appPath : 'https://anandalokcms.abp.in/',
  baseImgPath : 'https://anandalokcms.abp.in/image/journal/article?img_id=',
  baseURL : 'https://anandalokcms.abp.in/api/jsonws/contentservice.content/',
  youtubeURL: 'https://www.youtube.com/embed/',
  mailURL: 'https://anandalokcms.abp.in/api/jsonws/contentservice.content/send-contact-us-mail/',
};

// export const environment = {
//   production: false, 
//   apiUrl: 'http://localhost:9191',
//   appPath : 'https://api.anandalok.in',
//   baseImgPath : 'https://api.anandalok.in/image/journal/article?img_id=',
//   baseURL : 'https://api.anandalok.in/api/jsonws/contentservice.content/',
//   youtubeURL: 'https://www.youtube.com/embed/',
//   mailURL: 'https://api.anandalok.in/api/jsonws/contentservice.content/send-contact-us-mail/',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
