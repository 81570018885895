import { Component, OnInit } from '@angular/core';
import { EventsService } from 'angular4-events';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ServicesService } from '../services.service';
import {Router} from '@angular/router';
import { Token } from '../Token';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  login:any=true;
  loginForm: FormGroup;
  logSubmitted = false;
  token: Token;

  constructor(private events: EventsService,private formBuilder: FormBuilder, private router: Router, private appService: ServicesService) {
    if(this.appService.getToken != null){
      this.router.navigate(['/']);
    }
    this.events.subscribe('token').subscribe((token)=>{
      this.token = this.appService.getToken;
    });

    this.events.subscribe('login').subscribe((user)=>{  
      this.login =  user;
    });
   }

   ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
   }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  

  onSubmit() {
    this.logSubmitted = true;
    if (this.loginForm.invalid) {
      console.log('Credential invalid');
      return;
    }

    this.appService.token(this.loginForm.value)
      .pipe()
      .subscribe(
        data => {
          console.log('Login successful :' + typeof data);
          //  this.alertService.success('Registration successful', true);
          // tslint:disable-next-line:no-shadowed-variable
          localStorage.setItem('token', JSON.stringify(data));
          this.events.publish('token', JSON.stringify(data));
          this.router.navigate(['/']);
        },
        error => {
          console.log('login Error');
          //  this.alertService.error(error);
          this.logSubmitted = false;
        });
  }
}
