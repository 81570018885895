import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../validator/must-match.validator';
import { ServicesService } from '../services.service';
import { Router } from '@angular/router';
import { Token } from '../Token';
import { EventsService } from 'angular4-events';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  userForm: FormGroup;
  regSubmitted = false;
  token: Token;
  constructor(private events: EventsService,private formBuilder: FormBuilder,private appService: ServicesService,private router: Router) {
    if(this.appService.getToken != null){
      this.router.navigate(['/']);
    }
    this.events.subscribe('token').subscribe((token)=>{
      this.token = this.appService.getToken;
    });
   }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  get u() { return this.userForm.controls; }

  onRegister() {
    this.regSubmitted = true;
    if (this.userForm.invalid) {
      console.log('Credential invalid');
      return;
    }
    else{
      this.appService.resistration(this.userForm.value)
      .pipe()
      .subscribe(
        data => {
          console.log('Registration Completed :' + typeof data);
        },
        error => {
          console.log('login Error');
          //  this.alertService.error(error);
          this.regSubmitted = false;
        });
      console.log('API Called: '+this.userForm);
    }
  }

}
