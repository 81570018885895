import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from "./Token";
import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ServicesService {

  private currentokenSubject: BehaviorSubject<Token>;
  public currentToken: Observable<Token>;

  constructor(private http: HttpClient) { 
        this.currentokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('token')));
        this.currentToken = this.currentokenSubject.asObservable();
  }

  public getPosts() { 
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}navigation`, {headers: headers});
  }

  public getTopMenu() {  
    let headers = new HttpHeaders(); // API 
    return this.http.get(`${environment.baseURL}navigation`, {headers: headers});
  }

  public getFooterMenu() {  
    let headers = new HttpHeaders(); // API 
    return this.http.get(`${environment.baseURL}navigation`, {headers: headers});
  }
  
  homeBanner() {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}get-home-gallery-list`, {headers: headers});
  }

  latestEdition(){
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}/latest-edition`, {headers: headers});
  }

  behindthesceen(catId:number) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'videolist/categoryId/'+catId, { headers: headers});
  }

  videoEventCategoryStrip(catId) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'videolist/categoryId/'+catId, { headers: headers});
  }

  anandaEventMainvideo(vid,catid) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'related-videolist/current-video-id/'+vid+'/category-id/'+catid, { headers: headers});
  }

  relatedVideo(vid,catid) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'related-videolist/current-video-id/'+vid+'/category-id/'+catid, { headers: headers});
  }

  getvideoDetails(vid) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'get-video-by-id/vid/'+vid, { headers: headers});
  }

  search(key) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+ 'search/keyword'+key+'/start/0/end/10', {headers: headers});
  }

  homeBlocksection(posi) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'get-content-by-page-position/page/home/position-name/HomePosition'+posi+'/category/0', {headers: headers});
  }
  /*
  homeSecMasonary(startNo, endNo) {
    let headers = new HttpHeaders();
    //return this.http.get(`${environment.baseURL}`+'Home-other-stories/category-id/'+secid+'/start/0/end/9', {headers: headers});
    return this.http.get(`${environment.baseURL}`+'Home-other-stories/start/'+startNo+'/end/'+endNo, {headers: headers});
  }
 */
  secTopStory(posi, secid) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'get-content-by-page-position/page/section/position-name/SectionPosition'+posi+'/category/'+secid, {headers: headers});
  }

  relCaro(catid, storyid) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'get-related-stories/category-id/'+catid+'/article-id/'+storyid, {headers: headers});
  }
/*
  secMasonary(secid,startNo, endNo) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'Section-other-stories/category-id/'+secid+'/start/'+startNo+'/end/'+endNo, {headers: headers});
  }
*/
  storyDetails(articleId, categoryName) {
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'get-story/article-id/'+articleId+'/categoryName/'+categoryName, {headers: headers});
  }

  searchResult(keyword, startNo, endNo){
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'search/keyword/'+keyword+'/start/'+startNo+'/end/'+endNo, {headers: headers});
  }

  public token(loginData) {
    const params = new URLSearchParams();
    params.append('username', loginData.username);
    params.append('password', loginData.password);
    params.append('grant_type', 'password');
    const httpOption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization : 'Basic ' + btoa('mobile:pin')
      })
    };
    return this.http.post<any>(`${environment.apiUrl}/oauth/token`, params.toString(), httpOption).pipe(map(token => {
      this.currentokenSubject.next(token);
      return token;
    }));
  }

  public sendMail(name, email, phone,subject, comment){
    let headers = new HttpHeaders();
    const url = `${environment.mailURL}`+'name/'+name+'/mail-id/'+email+'/phone/'+phone+'/subject/'+subject+'/comment/'+comment;
    console.log(url);
    return this.http.get(url, {headers: headers});
  }

  public resistration(userForm) {
    const params = new URLSearchParams();
    params.append('username', userForm.username);
    params.append('password', userForm.password);
    params.append('confirmPassword', userForm.confirmPassword);
    const httpOption = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };
    return this.http.post<any>(`${environment.apiUrl}/registration`, params.toString(), httpOption);
  }
  
  public getphotoDetails(gellaryId: number){
    const httpOption = {
      headers: new HttpHeaders()
    };
    return this.http.get(`${environment.baseURL}gallery-item/gallery-id/`+gellaryId, httpOption);
  }

  public getphotoSlider(catid: number){  
    let headers = new HttpHeaders();
    return this.http.get(`${environment.baseURL}`+'gallerylist/categoryId/'+catid+'/start/0/end/1', {headers: headers});
  }

  public getTopPhotos(){
    const httpOption = {
      headers: new HttpHeaders()
    };
    return this.http.get(`${environment.baseURL}gallerylist/categoryId/46869/start/0/end/9`, httpOption);
  }

  public moreRecords(url: any, secid: number, startNum: number, endNum:number){
    const httpOption = {
      headers: new HttpHeaders()
    };
    
    return this.http.get(url, httpOption);
  }

  getRelatedPhoto(categoryId: number) {
    const httpOption = {
      headers: new HttpHeaders()
    };
    const url = environment.baseURL+'gallerylist/categoryId/'+categoryId+'/start/0/end/9';
    return this.http.get(url, httpOption);
  }

  public get getToken(): Token {
    return this.currentokenSubject.value as unknown as Token;
  }

  public clearToken(): Token {
    this.currentokenSubject.next(null);
    return localStorage.removeItem('token') as unknown as Token;
  }

}
