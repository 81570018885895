import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SubdetailsComponent } from'./subdetails/subdetails.component';
import { StorydetailsComponent } from'./storydetails/storydetails.component';
import { from } from 'rxjs';
import { SectionComponent } from './section/section.component';
import { PhotogalleryComponent } from './photogallery/photogallery.component';
import { PhotodetailsComponent } from './photodetails/photodetails.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermscondComponent } from './termscond/termscond.component';
import { BehindthesceenComponent } from './behindthesceen/behindthesceen.component';
import { SearchresultComponent } from './searchresult/searchresult.component';
import { AnandalokeventComponent } from './anandalokevent/anandalokevent.component';
import { VideodetailsComponent } from './videodetails/videodetails.component';
import { RegistrationComponent } from './registration/registration.component';



const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'logout', component: LoginComponent },
  { path: 'subdetails', component: SubdetailsComponent },
  { path: 'storydetails', component: StorydetailsComponent },
  { path: 'section', component: SectionComponent},
  { path: 'photogallery', component: PhotogalleryComponent},
  { path: 'photodetails', component: PhotodetailsComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'about', component: AboutComponent},
  { path: 'privacypolicy', component: PrivacypolicyComponent},
  { path: 'termscond', component: TermscondComponent},
  { path: 'behindthesceen', component: BehindthesceenComponent},
  { path: 'searchresult', component: SearchresultComponent},
  { path: 'anandalokevent', component: AnandalokeventComponent},
  { path: 'videodetails', component: VideodetailsComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
