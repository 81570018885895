import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { EventsService } from 'angular4-events';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photogallery',
  templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.css']
})
export class PhotogalleryComponent implements OnInit {

  photos: any;
  topPhotos: any;
  baseURL: any;
  cstart: any = 1;
  cend: any = 9;
  moredata: any;
  loadmoretxt: string;
  categoryId: any;
  constructor(private service:ServicesService,public activeRoute: ActivatedRoute,private router : Router) { }

  ngOnInit() {
    this.baseURL=environment.appPath;
    this.activeRoute.queryParams.subscribe(params => {
      this.categoryId = params['categoryId'];
    });
    this.service.getphotoSlider(this.categoryId).subscribe((data)=>{
      console.log(data[0]['galleryId']);
      this.service.getphotoDetails(data[0]['galleryId']).subscribe((dataDtls)=>{
          this.photos = dataDtls;
      });
    });
    
    //this.service.getTopPhotos().subscribe((photoDtls)=>{
      const url = environment.baseURL+'gallerylist/categoryId/'+this.categoryId+'/start/'+this.cstart+'/end/'+this.cend;
      this.service.moreRecords(url, this.categoryId, this.cstart, this.cend).subscribe((photoDtls)=>{
      //console.log(photoDtls);
      this.topPhotos = photoDtls;
      this.cend=this.topPhotos.length;
    });
    
    //console.log('photos :'+this.photos);
  }

  goTophotoDetails(id: number){
    console.log('photos Id :'+id);
    this.router.navigate(['photodetails'],{ queryParams: {id : id, catid: this.categoryId}});
  }

  secLoadMore(secid, cend){
    //console.log(this.counter + 'dat size'+this.msonry.length);
    this.cstart=cend + 1;
    this.cend=this.cstart + 8;
    const url = environment.baseURL+'gallerylist/categoryId/'+secid+'/start/'+this.cstart+'/end/'+this.cend;
    this.service.moreRecords(url, secid, this.cstart, this.cend).subscribe(
      data => {
        console.log(data);
        this.moredata=data;      
        this.topPhotos = this.topPhotos.concat(this.moredata);
  
         if(this.moredata.length==0){
          this.loadmoretxt="NO MORE PHOTOS";
         }
  
      },
      error=>{
        console.log(error);   
      });
  }

}
