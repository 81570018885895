import { Component, OnInit } from '@angular/core';

import { ServicesService } from '../services.service';
import { Router,ActivatedRoute } from "@angular/router";
import { DomSanitizer} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-behindthesceen',
  templateUrl: './behindthesceen.component.html',
  styleUrls: ['./behindthesceen.component.css']
})
export class BehindthesceenComponent implements OnInit {
  secId:any;
  title:any={};
  video:any={};
  secstr1:any={};
  secstrvid:any={};
  themekey:any={};
  videoscenes:any=[];
  sectionName:any=[];
  selectedItem: any;
  vtag: any;
  author: any;
  dates: any;
  youvid: any;
  catid: any;
  topNews: any;
  cstart: any = 0;
  cend: any = 9;
  moredata:any=[];
  loadmoretxt:any="LOAD MORE";


  constructor(private router : Router, public activeRoute: ActivatedRoute, private services: ServicesService, private dom: DomSanitizer) {

   }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      this.secId = params['categoryId'];
      this.title = params['title'];
      // this.sName = params['secName'];
      this.video = params['videoId'];
      this.sectionName = params['nameCurrentValue'];

      this.themekey = params['themekey'];
      // this.parent = params['parent'];
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.initialiseState(this.secId); // reset and set based on new parameter this time

  });
  }

  initialiseState(secId){
    this.services.behindthesceen(this.secId).subscribe(
      data => {
        this.videoscenes=data;
        console.log(data);
        this.vtag=this.videoscenes[0].tags;
        this.selectedItem = this.videoscenes[0].videoId;
        this.title=this.videoscenes[0].title;
        this.author=this.videoscenes[0].author;
        this.dates=this.videoscenes[0].dateString;  
        this.youvid=this.dom.bypassSecurityTrustResourceUrl(environment.youtubeURL+this.selectedItem+'?rel=0&autoplay=1');
      },
      error =>{
        console.log(error);   
      });

    }

    openBig(vdo){
      console.log(vdo);
      this.selectedItem = vdo.videoId;
      this.title=vdo.title;
      this.author=vdo.author;
      this.dates=vdo.dateString;
      this.vtag=vdo.tags;
      this.youvid=this.dom.bypassSecurityTrustResourceUrl(environment.youtubeURL+this.selectedItem+'?rel=0&autoplay=1');

      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, 0); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
    }

    secLoadMore(secId, cend) {
      //console.log(this.counter + 'dat size'+this.msonry.length);
      this.cstart=cend + 1;
      this.cend=this.cstart + 8;
      const url = environment.baseURL+'videolist/categoryId/'+secId+'/start/'+this.cstart+'/end/'+this.cend;
      this.services.moreRecords(url, secId, this.cstart, this.cend).subscribe(
        data => {
          console.log(data);
          this.moredata=data;      
          this.videoscenes = this.videoscenes.concat(this.moredata);

          if(this.moredata.length==0){
            this.loadmoretxt="NO MORE STORIES";
           }
        },
        error=>{
          console.log(error);   
        });
    }

}
