import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { ServicesService } from './services.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import { ErrordialogComponent } from './errordialog/errordialog.component'; 
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';

// Import NgMasonryGridModule
import { NgMasonryGridModule } from 'ng-masonry-grid';
import { EventsModule } from 'angular4-events';
import { MatMenuModule } from '@angular/material/menu';
import { OwlModule } from 'ngx-owl-carousel'; 


import { AppRoutingModule } from './app-routing.module';
import { NavpanelComponent } from './navpanel/navpanel.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { TopnavComponent } from './topnav/topnav.component';
import { SubdetailsComponent } from './subdetails/subdetails.component';
import { from } from 'rxjs';
import { StorydetailsComponent } from './storydetails/storydetails.component';
import { SectionComponent } from './section/section.component';
import { PhotogalleryComponent } from './photogallery/photogallery.component';
import { PhotodetailsComponent } from './photodetails/photodetails.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermscondComponent } from './termscond/termscond.component';
import { BehindthesceenComponent } from './behindthesceen/behindthesceen.component';
import { VideoComponent } from './video/video.component';
import { SearchresultComponent } from './searchresult/searchresult.component';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { AnandalokeventComponent } from './anandalokevent/anandalokevent.component';
import { VideodetailsComponent } from './videodetails/videodetails.component';
import { RegistrationComponent } from './registration/registration.component';

library.add(fas, fab);

@NgModule({
  declarations: [
    AppComponent,
    NavpanelComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    TopnavComponent,
    SubdetailsComponent,
    StorydetailsComponent,
    SectionComponent,
    PhotogalleryComponent,
    PhotodetailsComponent,
    ErrordialogComponent,
    ContactComponent,
    AboutComponent,
    PrivacypolicyComponent,
    TermscondComponent,
    BehindthesceenComponent,
    VideoComponent,
    SearchresultComponent,
    AnandalokeventComponent,
    VideodetailsComponent,
    RegistrationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    EventsModule.forRoot(),
     // Specify NgMasonryGrid library as an import
     NgMasonryGridModule,
     MatExpansionModule,
     MatMenuModule,
     MatSliderModule,
     OwlModule,
     FormsModule,
     ReactiveFormsModule,
     MatDialogModule,
     HttpClientModule,       // (Required) For share counts
    HttpClientJsonpModule,  // (Optional) Add if you want tumblr share counts
    ShareButtonsModule
  ],
  entryComponents: [ErrordialogComponent],
  providers: [ ServicesService, { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }],
  bootstrap: [AppComponent]
})

export class AppModule { }
